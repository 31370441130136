import Img from "gatsby-image"
import ContactForm from "../components/ContactForm/ContactForm"
import Layout from "../components/Layout"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => {
  const {
    header_image,
    title,
    content,
  } = data.allPrismicContactPage.edges[0].node.data
  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="contact-page">
        <div className="contact-page__image-container">
          <h1 className="contact-page__title">{title.text}</h1>
          <Img fluid={header_image.fluid} />
        </div>
        <div className="contact-page__content-container">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.8396889662313!2d-0.43878733422972016!3d51.51615702963656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766d88701a65e1%3A0xcac5b3eabae59e08!2sPrinces%20Park%20Ln%2C%20Hayes%20UB3%201JU!5e0!3m2!1sen!2suk!4v1574490762084!5m2!1sen!2suk"
            frameborder="0"
            style={{ width: "100%", height: 300, border: 0 }}
            allowfullscreen=""
          ></iframe>
          <div
            className="contact-page__content-container--html"
            dangerouslySetInnerHTML={{ __html: content.html }}
          ></div>
          <hr />
          <div className="contact-form" style={{ padding: 20 }}>
            <h2>Send us a message</h2>
            <ContactForm type="quote" isQuotePage={false} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    allPrismicContactPage {
      edges {
        node {
          data {
            header_image {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            title {
              text
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`

export default ContactPage
