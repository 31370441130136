import emailjs from "@emailjs/browser"
import React, { useState } from "react"

import Input from "./Input"

const ContactForm = ({ title, type, isQuotePage = true }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState({
    quoteFormName: "",
    quoteFormPhone: "",
    quoteFormEmail: "",
    pickupAddress: "",
    vehicleSelection: "S Class (Luxury)",
    dropoffAddress: "",
    pickupTime: "",
    pickupDate: "",
    numberOfPassengers: "",
    numberOfBags: "",
    specialAssistance: "",
    additionalInfo: "",
  })

  const isSubmitable =
    data.quoteFormName &&
    data.quoteFormEmail &&
    data.quoteFormPhone &&
    (!isQuotePage ? data.additionalInfo : true)

  const onSubmit = e => {
    setError(false)
    setLoading(true)
    e.preventDefault()

    if (!isSubmitable) return

    const templateId = isQuotePage ? "template_chi2noa" : "template_bwmts6t"

    emailjs.send("service_dru8lxs", templateId, data, "DKg8H6g9AvOBiLsYd").then(
      function(response) {
        if (response.status === 200 && response.text === "OK") {
          setLoading(false)
          window.location.replace("/thank-you")
        }
      },
      function() {
        setLoading(false)
        setError(true)
      }
    )
  }

  return (
    <div className="contact-form">
      <form name={`${type}-form`} onSubmit={onSubmit} method="POST">
        <Input
          label="Name"
          name="quoteFormName"
          value={data.quoteFormName}
          required
          onChange={e =>
            setData({
              ...data,
              quoteFormName: e.target.value,
            })
          }
        />
        <Input
          label="Phone"
          name="quoteFormPhone"
          value={data.quoteFormPhone}
          required
          onChange={e =>
            setData({
              ...data,
              quoteFormPhone: e.target.value,
            })
          }
        />
        <Input
          label="Email"
          name="quoteFormEmail"
          value={data.quoteFormEmail}
          required
          onChange={e =>
            setData({
              ...data,
              quoteFormEmail: e.target.value,
            })
          }
          type="email"
        />
        {isQuotePage ? (
          <>
            <Input
              label="Pickup Address"
              name="pickupAddress"
              value={data.pickupAddress}
              onChange={e =>
                setData({
                  ...data,
                  pickupAddress: e.target.value,
                })
              }
              textarea
            />
            <div className="contact-form__vehicle-select">
              <label htmlFor="">Select your vehicle</label>
              <select
                name="vehicleSelection"
                value={data.vehicleSelection}
                onChange={e =>
                  setData({
                    ...data,
                    vehicleSelection: e.target.value,
                  })
                }
              >
                <option value="S Class (Luxury)">S Class (Luxury)</option>
                <option value="E Class (Business)">E Class (Business)</option>
                <option value="V Class (MPV 7 seater)">
                  V Class (MPV 7 seater)
                </option>
              </select>
            </div>
            <Input
              label="Dropoff Address"
              name="dropoffAddress"
              value={data.dropoffAddress}
              onChange={e =>
                setData({
                  ...data,
                  dropoffAddress: e.target.value,
                })
              }
              textarea
            />
            <Input
              label="Pickup time"
              name="pickupTime"
              value={data.pickupTime}
              onChange={e =>
                setData({
                  ...data,
                  pickupTime: e.target.value,
                })
              }
            />
            <Input
              label="Pickup date"
              name="pickupDate"
              value={data.pickupDate}
              onChange={e =>
                setData({
                  ...data,
                  pickupDate: e.target.value,
                })
              }
            />
            <Input
              label="Number of passengers"
              name="numberOfPassengers"
              value={data.numberOfPassengers}
              onChange={e =>
                setData({
                  ...data,
                  numberOfPassengers: e.target.value,
                })
              }
            />
            <Input
              label="Number of bags/cases"
              name="numberOfBags"
              value={data.numberOfBags}
              onChange={e =>
                setData({
                  ...data,
                  numberOfBags: e.target.value,
                })
              }
            />
            <Input
              label="Special Assistance Required?"
              name="specialAssistance"
              value={data.specialAssistance}
              onChange={e =>
                setData({
                  ...data,
                  specialAssistance: e.target.value,
                })
              }
            />
          </>
        ) : null}
        <Input
          label={isQuotePage ? "Additional Info" : "Message"}
          name="additionalInfo"
          value={data.additionalInfo}
          onChange={e =>
            setData({
              ...data,
              additionalInfo: e.target.value,
            })
          }
          textarea
        />

        <button type="submit" disabled={!isSubmitable || loading}>
          {loading ? "..." : "Send"}
        </button>
        {error ? (
          <span style={{ display: "block", color: "red", marginTop: 8 }}>
            Something went wrong. Try again later.
          </span>
        ) : null}
      </form>
    </div>
  )
}

export default ContactForm
