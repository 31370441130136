import "./ContactForm.scss"

import React from "react"

const Input = ({
  label,
  name,
  type = "text",
  textarea,
  onChange,
  required = false,
}) => {
  if (textarea) {
    return (
      <div className="contact-form__input">
        <label>{label}</label>
        <textarea name={name} cols="30" rows="5" onChange={onChange}></textarea>
      </div>
    )
  }

  return (
    <div className="contact-form__input">
      <label>{label}</label>
      <input type={type} name={name} onChange={onChange} required={required} />
    </div>
  )
}

export default Input
